<template>
  <v-content>
    <v-row justify="center">
      <v-col cols="8">
        <h2 class="text-lg-h3">
          {{ institutional.title }}
        </h2>
      </v-col>
    </v-row>
    <v-row
      class="mt-10"
      justify="center"
    >
      <v-col
        cols="8"
        v-html="institutional.text"
      />
    </v-row>
  </v-content>
</template>

<script>
import { findBySlug } from '@/services/institutional-service'

export default {
  name: 'ReadInstitutional',
  data () {
    return {
      institutional: {}
    }
  },
  async created () {
    await this.loadData()
  },
  methods: {
    async loadData () {
      try {
        const data = await findBySlug(this.$route.params.slugInstitutionalText)
        this.institutional = data
      } catch (e) {
        this.$handleHttpError(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
